@use '../utils/media-queries.scss' as *;
@import "../utils/variables.scss";

.Workshops {
    display: flex;
    flex-direction: column;
    padding: 7rem 0 7rem 0;
    text-align: center;
    gap: 2rem;
    max-width: 1320px;
    padding: 1rem;
    @include xs {
        width: 95%;
        padding: 2rem 0 2rem 0;
    }
    h1 {
        font: $workshop-title-font;
        color: $workshop-title-color;
    }
    h3 {
        font: $workshop-text-font;
        color: $workshop-text-color;
    }
    .Workshop-Grid {
        display: flex;
        justify-content: center;
        align-self: center;
        flex-wrap: wrap;
        @include xs {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            justify-content:unset;
            align-self: unset;
            scroll-snap-type: x mandatory;
        }
    }
}