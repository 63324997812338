@use '../utils/media-queries.scss' as *;
@import "../utils/variables.scss";
.Workshop-card-wrapper {
    width: 25%;
    padding-right: 0.75rem;
    margin-bottom: 1rem;
    @include xs {
        padding-right: 0rem;
        margin-right: 2rem;
        flex: 0 0 100%;
        scroll-snap-align: start;
    }
    @include sm {
        width: 50%;
    }
    @include md {
        width: 33%;
    }
    .Workshop-Card {
        display: flex;
        position: relative;
        height: 500px;
        overflow: hidden;
        border-radius: 20px;
        
        &:hover {
            img {
                scale: 1.1;
            }
            
        }
        .inner {
            z-index: 1;
            padding: 20px;
            display: flex;
            flex-direction: column;
            .super-text {
                height: fit-content;
                margin-bottom: 4rem;
                display: flex;
                align-content: center;
                h1 {
                    text-decoration: underline;
                    font: $workshop-card-super-text-font;
                    color: $workshop-card-super-text-color;
                }
            }
            .text-container {
                text-align: left;
                overflow-y: auto;
                visibility: visible;
                scrollbar-width: thin;
                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: rgba(0, 0, 0, 0.25);
                    border-radius: 100px;
                }
                &::-webkit-scrollbar-thumb {
                    width: 5px;
                    background-color: rgba(200, 200, 200, 0.5);
                    border-radius: 100px;
                }
                h1 {
                    font: $workshop-card-title-font;
                    color: $workshop-card-title-color;
                }
                
                p {
                    
                    font: $workshop-card-text-font; 
                    color: $workshop-card-text-color;
                    @include xs {
                        font: $workshop-card-mobile-text-font;
                    }
                    @include sm {
                        font: $workshop-card-mobile-text-font;
                    }
                }
            }
        }
        img {
            
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit:cover;
            filter: blur(3px);
            transition: all 0.3s ease-in-out;
            z-index: 0;
        
        }
        
        .background-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            z-index: 1;
            background: $workshop-card-overlay;
        }
    }
}