@use '../utils/media-queries.scss' as *;

.Main-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  
  .Scroll-Container {
    display: block;
  }
  .loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 1);
    z-index: 10;
    
    .spinner {
      width: 64px;
      height: 64px;
      border: 8px solid;
      border-color: #3d5af1 #3d5af1 #3d5af1 transparent;
      border-radius: 50%;
      animation: spin-anim 0.6s linear infinite;
    }
    @keyframes spin-anim {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}


