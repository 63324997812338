@use '../utils/media-queries.scss' as *;
@import "../utils/variables.scss";

.fablab {
    display: flex;
    width: 80%;
    padding: 7rem 0 7rem 0;
    gap: 2rem;
    max-width: 1320px;
    @include xs {
        flex-direction: column;
    }
    @include sm {
        flex-direction: column;
    }
    .image {
        position: relative;
        display: flex;
        height: 100%;
        width: 50%;
        overflow:hidden;
        @include xs {
            width: 100%;
        }
        @include sm {
            width: 100%;
        }
        img {
            border-radius: 10%;
            flex: 0 0  auto;
            height: 100%;
            width: 100%;
            content: url($fablab-image);
        }
        
        .text {
            position: absolute;
            width: 100%;
            top: 70%;
            left: 5%;
            text-align: left;
            h4 {
                color: $fablab-image-text-color;
                font: $fablab-image-text-font;
                @include lt-md {
                    font: $fablab-image-text-font-mobile;
                }
            }
        }
        
        .background-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 10%;
            background-image: $fablab-image-overlay;
        }
        
        
    }
    .description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        width: 50%;
        @include xs {
            width: 100%;
        }
        @include sm {
            width: 100%;
        }
        h1 {
            font: $fablab-title-font;
            color: $fablab-text-color;
        }
        p {
            font: $fablab-text-font;
            color: $fablab-text-color;
        }
    }
}